import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card, Form } from '../../../components/basic';
import { View, CustomInput } from '../../../shared/components';
import { ClientAdvanceGraph, ClientAdvancesPerDay, ToggleSwitch } from '../../../components';

import { FaBan, FaCheck, FaChartBar, FaFileInvoice, FaPlus, FaInfoCircle, FaFileCsv, FaTrash } from 'react-icons/fa';
import ReactFileReader from 'react-file-reader';

import '../../style.scss';
import './style.scss';

const xlsx = require('xlsx');

const Client = observer(() => {

    const commonStore = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [Clients, setClients] = useState([]);
    const [SelectedCompanyIndex, setSelectedCompanyIndex] = useState('-1');
    const [SelectedCompany, setSelectedCompany] = useState({});
    const [Advances, setAdvances] = useState([]);
    const [ShowAdvances, setShowAdvances] = useState(true);
    const [ShowFees, setShowFees] = useState(true);
    const [TotalAdvances, setTotalAdvances] = useState(0);
    const [TotalFees, setTotalFees] = useState(0);
    const [Employees, setEmployees] = useState(0);
    const [EmployeeList, setEmployeeList] = useState([]);
    const [EmployeesSignedUp, setEmployeesSignedUp] = useState(0);
    const [PendingInvoices, setPendingInvoices] = useState([]);
    const [ClientAddress, setClientAddress] = useState(['', '', '', '']);
    const [ClientVAT, setClientVAT] = useState('');
    const [AllowAdvances, setAllowAdvances] = useState(false);
    const [InvoiceDate, setInvoiceDate] = useState(0);

    const [ShowNewCompany, setShowNewCompany] = useState(false);
    const [NewCompanyName, setNewCompanyName] = useState('');
    const [NewCompanyVAT, setNewCompanyVAT] = useState('');
    const [NewCompanyAddress, setNewCompanyAddress] = useState(['', '', '', '']);
    const [NewCompanyReg, setNewCompanyReg] = useState('');
    const [NewCompanyAPIName, setNewCompanyAPIName] = useState('noApi');
    const [NewCompanyAPI, setNewCompanyAPI] = useState('');
    const [NewCompanyAPIUsername, setNewCompanyAPIUsername] = useState('');
    const [NewCompanyAPIPassword, setNewCompanyAPIPassword] = useState('');
    const [NewCompanyAPIPayrollDef, setNewCompanyAPIPayrollDef] = useState('');
    const [NewCompanyUserEmail, setNewCompanyUserEmail] = useState('');
    const [NewCompanyUserPassword, setNewCompanyUserPassword] = useState('');
    const [NewCompanyUserConfirmPassword, setNewCompanyUserConfirmPassword] = useState('');
    const [NewCompanyAllowAdvances, setNewCompanyAllowAdvances] = useState(false);
    const [NewCompanyInvoiceDate, setNewCompanyInvoiceDate] = useState(0);

    const [CompanyAPI, setCompanyAPI] = useState('');
    const [CompanyAPIUsername, setCompanyAPIUsername] = useState('');
    const [CompanyAPIPassword, setCompanyAPIPassword] = useState('');
    const [CompanyAPIPayrollDef, setCompanyAPIPayrollDef] = useState('');

    const [allowBulkUpdates, setAllowBulkUpdates] = useState(false);
    const [excelJsonData, setExcelJsonData] = useState([]);

    const [CSVEmployees, setCSVEmployees] = useState([]);
    const [activePage, setActivePage] = useState('Info');

    useEffect(() => {
        loadClients();

    }, []);

    const fileInputRef = useRef(null);

    const clearFileInput = () => {
        fileInputRef.current.value = ''; // Reset file input value to empty string
    };

    function loadClients() {
        commonStore.setLoading(true);
        commonStore.getAllClients().then((ret) => {
            setClients(ret);
            commonStore.setLoading(false);
        })
    }

    function clientSelected(e) {
        clearClientData()
        if (e.target.value === '-1') {
            setSelectedCompanyIndex(e.target.value);
            return
        }
        setSelectedCompanyIndex(e.target.value);
        setSelectedCompany(Clients[Number(e.target.value)]);
        let addr = Clients[Number(e.target.value)].address || '';
        var addrArr = ['', '', '', ''];
        if (addr === '') {
            addrArr = ['', '', '', ''];
        } else {
            addrArr = addr.split('/ ');
        }
        let vatnr = Clients[Number(e.target.value)].registrationNumber || '';
        setCompanyAPI(Clients[Number(e.target.value)].api || '')
        setCompanyAPIUsername(Clients[Number(e.target.value)].apiUsername || '')
        setCompanyAPIPassword(Clients[Number(e.target.value)].apiPassword || '')
        setCompanyAPIPayrollDef(Clients[Number(e.target.value)].payrollDefinitionCode || '')
        setClientVAT(vatnr);
        setClientAddress(addrArr);
        setAllowAdvances(Clients[Number(e.target.value)].allowAdvances || false);
        setInvoiceDate(Clients[Number(e.target.value)].invoiceDate || 0);
        loadClientData(Clients[Number(e.target.value)]);
        console.log(SelectedCompany)
    }

    function clearClientData() {
        setSelectedCompany({});
        setAdvances([]);
        setShowAdvances(true);
        setShowFees(true);
        setTotalAdvances(0);
        setTotalFees(0);
        setEmployeeList([]);
        setEmployees(0);
        setEmployeesSignedUp(0);
        setPendingInvoices([]);
        setClientAddress(['', '', '', '']);
        setClientVAT('');
        setCompanyAPI('');
        setCompanyAPIUsername('');
        setCompanyAPIPassword('');
        setCompanyAPIPayrollDef('');
        setAllowAdvances(false);
    }

    function loadClientData(client) {
        commonStore.setLoading(true);
        commonStore.getAdvancesForClient(client._id).then((ret) => {
            console.log(ret);
            let successfulAdvances = ret.advances.filter(adv => adv.transactionhistory && adv.transactionhistory.TransactionStatus !== 'Failed')
            setAdvances(successfulAdvances);
            let adv = 0;
            let fee = 0;
            for (let i = 0; i < successfulAdvances.length; i++) {
                adv += successfulAdvances[i].AdvanceAmount;
                fee = successfulAdvances[i].AdvanceFee;
            }
            setTotalAdvances(adv);
            setTotalFees(fee);
            getEmployees(client._id);
        })
    }

    function getEmployees(clientId) {
        commonStore.setLoading(true);
        commonStore.getEmployeesByBusinessEntity(clientId).then((ret) => {
            console.log(ret);
            setEmployeeList(ret);
            setEmployees(ret.length);
            let empSignedUp = ret.filter(emp => emp.user && emp.user.confirmed);
            setEmployeesSignedUp(empSignedUp.length)
            getInvoices(clientId)
        })
    }

    function getInvoices(clientId) {
        commonStore.setLoading(true);
        console.log(SelectedCompany)
        commonStore.getInvoicesByClient(clientId).then((ret) => {
            let pendingInv = ret.filter(inv => inv.Status === 'Pending');
            setPendingInvoices(pendingInv);
            commonStore.setLoading(false);
            forceUpdate()
        })
    }

    function toggleAdvances() {
        setShowAdvances(!ShowAdvances);
    }

    function toggleFees() {
        setShowFees(!ShowFees);
    }

    function downloadInvoice(e) {
        return
        let invoice = (PendingInvoices.filter(inv => inv._id === e.currentTarget.id))[0];
        commonStore.exportExcelStatement(invoice.Advances, invoice.Businessentity, invoice.InvoiceNumber)
    }
    function cancelInvoice(e) {
        if (!window.confirm('Are you sure you want to mark this invoice as cancelled?')) {
            return
        }
        let id = e.currentTarget.id
        let data = {
            Status: 'Cancelled'
        }
        commonStore.setLoading(true);
        commonStore.updateInvoice(id, data).then((ret) => {
            window.alert('Invoice updated successfully');
            commonStore.setLoading(false);
            getInvoices(SelectedCompany._id);
        })
    }
    function approveInvoice(e) {
        if (!window.confirm('Are you sure you want to mark this invoice as paid?')) {
            return
        }
        let id = e.currentTarget.id
        let data = {
            Status: 'Cancelled'
        }
        commonStore.setLoading(true);
        commonStore.updateInvoice(id, data).then((ret) => {
            window.alert('Invoice updated successfully');
            commonStore.setLoading(false);
            getInvoices(SelectedCompany._id);
        })
    }

    function addressChanged(e) {
        if (e.currentTarget.value.includes('/')) {
            window.alert('Address lines cannot contain slashes, each line will be seperated by a slash.');
            return
        }
        let addrArr = ClientAddress;
        if (e.currentTarget.id === 'line1') {
            addrArr[0] = e.currentTarget.value;
        }
        if (e.currentTarget.id === 'line2') {
            addrArr[1] = e.currentTarget.value;
        }
        if (e.currentTarget.id === 'line3') {
            addrArr[2] = e.currentTarget.value;
        }
        if (e.currentTarget.id === 'line4') {
            addrArr[3] = e.currentTarget.value;
        }

        setClientAddress(addrArr);
        forceUpdate();
    }

    function updateAddress() {
        let addr = ClientAddress.join('/ ');
        let id = SelectedCompany._id;
        let data = { address: addr };
        commonStore.setLoading(true);
        commonStore.updateClientById(id, data).then((ret) => {
            window.alert('Company Address Updated');
            commonStore.setLoading(false);
        })
    }

    function VATChanged(e) {
        setClientVAT(e.target.value);
    }

    function updateVAT() {
        let data = {
            registrationNumber: ClientVAT
        }
        let id = SelectedCompany._id;
        commonStore.setLoading(true);
        commonStore.updateClientById(id, data).then((ret) => {
            window.alert('Company VAT Updated');
            // commonStore.setLoading(false);
            loadClients();
        })
    }

    function newCompanyClicked() {
        setShowNewCompany(true);
    }

    function newCompanyChange(e) {
        if (e.currentTarget.id === 'name') {
            setNewCompanyName(e.target.value);
        }
        if (e.currentTarget.id === 'reg') {
            setNewCompanyReg(e.target.value);
        }
        if (e.currentTarget.id === 'vat') {
            setNewCompanyVAT(e.target.value);
        }
        if (e.currentTarget.id === 'line1') {
            let addr = NewCompanyAddress;
            addr[0] = e.target.value;
            setNewCompanyAddress(addr);
            forceUpdate();
        }
        if (e.currentTarget.id === 'line2') {
            let addr = NewCompanyAddress;
            addr[1] = e.target.value;
            setNewCompanyAddress(addr);
            forceUpdate();
        }
        if (e.currentTarget.id === 'line3') {
            let addr = NewCompanyAddress;
            addr[2] = e.target.value;
            setNewCompanyAddress(addr);
            forceUpdate();
        }
        if (e.currentTarget.id === 'line4') {
            let addr = NewCompanyAddress;
            addr[3] = e.target.value;
            setNewCompanyAddress(addr);
            forceUpdate();
        }
        if (e.currentTarget.id === 'api') {
            setNewCompanyAPI(e.target.value);
        }
        if (e.currentTarget.id === 'apiUser') {
            setNewCompanyAPIUsername(e.target.value);
        }
        if (e.currentTarget.id === 'apiPass') {
            setNewCompanyAPIPassword(e.target.value);
        }
        if (e.currentTarget.id === 'apiPay') {
            setNewCompanyAPIPayrollDef(e.target.value);
        }
        if (e.currentTarget.id === 'email') {
            setNewCompanyUserEmail(e.target.value);
        }
        if (e.currentTarget.id === 'pass') {
            setNewCompanyUserPassword(e.target.value);
        }
        if (e.currentTarget.id === 'passConf') {
            setNewCompanyUserConfirmPassword(e.target.value);
        }

        if (e.currentTarget.id === 'invoiceDate') {
            setNewCompanyInvoiceDate(Number(e.target.value));
        }

        if (e.currentTarget.id === 'allowAdv') {
            setNewCompanyAllowAdvances(e.target.value);
        }
    }

    function apiNameChanged(e) {
        setNewCompanyAPIName(e.target.value);
    }

    function testLabournet() {
        let data = {
            username: NewCompanyAPIUsername,
            password: NewCompanyAPIPassword,
            instance: NewCompanyAPI
        }
        commonStore.setLoading(true);
        commonStore.testLabournet(data).then((ret) => {
            if (ret) {
                window.alert('API details correct');
                commonStore.setLoading(false);
            } else {
                window.alert('API details incorrect');
                commonStore.setLoading(false);
            }
        })
    }

    function testPaySpace() {
        let data = {
            clientId: NewCompanyAPIUsername,
            clientSecret: NewCompanyAPIPassword,
            scope: NewCompanyAPI
        }
        commonStore.setLoading(true);
        commonStore.testPaySpace(data).then((ret) => {
            if (ret) {
                window.alert('API details correct');
                commonStore.setLoading(false);
            } else {
                window.alert('API details incorrect');
                commonStore.setLoading(false);
            }
        })
    }

    function cancelCompanyCreation() {
        setShowNewCompany(false);
        setNewCompanyName('');
        setNewCompanyVAT('');
        setNewCompanyAddress(['', '', '', '']);
        setNewCompanyReg('');
        setNewCompanyAPIName('noApi');
        setNewCompanyAPI('');
        setNewCompanyAPIUsername('');
        setNewCompanyAPIPassword('');
        setNewCompanyAPIPayrollDef('');
        setNewCompanyUserEmail('');
        setNewCompanyUserPassword('');
        setNewCompanyUserConfirmPassword('');
        setNewCompanyAllowAdvances(false);
        setNewCompanyInvoiceDate(0);
    }

    function createCompany() {
        if (NewCompanyName === '') {
            window.alert('Please enter company name.');
            return
        }

        if (NewCompanyUserEmail === '' || NewCompanyUserPassword === '') {
            window.alert('Please fill in user details');
            return
        }

        if (NewCompanyUserPassword !== NewCompanyUserConfirmPassword) {
            window.alert('Passwords do not match');
            return
        }

        let data = {
            name: NewCompanyName,
            registrationNumber: NewCompanyVAT,
            regcode: NewCompanyReg,
            address: NewCompanyAddress.join('/ '),
            active: true,
            allowAdvances: NewCompanyAllowAdvances,
            invoiceDate: Number(NewCompanyInvoiceDate)
        }

        if (NewCompanyAPIName === 'noApi') {
            data.status = 'csv'
        }
        if (NewCompanyAPIName === 'LabourNet') {
            if (NewCompanyAPI === '' || NewCompanyAPIUsername === '' || NewCompanyAPIPassword === '' || NewCompanyAPIPayrollDef === '') {
                data.status = 'api_missingInfo'
            } else {
                data.status = 'api_inactive'
            }
            data.api_name = 'LabourNet';
            data.api = NewCompanyAPI;
            data.apiUsername = NewCompanyAPIUsername;
            data.apiPassword = NewCompanyAPIPassword;
            data.payrollDefinitionCode = NewCompanyAPIPayrollDef;
        }

        if (NewCompanyAPIName === 'PaySpace') {
            if (NewCompanyAPI === '' || NewCompanyAPIUsername === '' || NewCompanyAPIPassword === '' || NewCompanyAPIPayrollDef === '') {
                data.status = 'api_missingInfo'
            } else {
                data.status = 'api_inactive'
            }
            data.api_name = 'PaySpace';
            data.api = NewCompanyAPI;
            data.apiUsername = NewCompanyAPIUsername;
            data.apiPassword = NewCompanyAPIPassword;
            data.payrollDefinitionCode = NewCompanyAPIPayrollDef;
        }

        let user = {
            username: NewCompanyUserEmail,
            email: NewCompanyUserEmail,
            password: NewCompanyUserPassword,
            confirmed: true,
            blocked: false,
        }

        commonStore.setLoading(true);
        commonStore.createClient(data, user).then((ret) => {
            window.alert('Client created successfully');
            commonStore.setLoading(false);
            cancelCompanyCreation();
            clearClientData();
            loadClients();

        })
    }

    const formatBankName = (bankName) => {
        let bank = bankName.replace(/\s+/g, '').toLocaleLowerCase();
        var ozowBankName;
        var branchCode;

        switch (bank) {
            case 'absa': ozowBankName = 'ABSA'; branchCode = '632005'; break;
            case 'absa-absa': ozowBankName = 'ABSA'; branchCode = '632005'; break;
            case 'absabank': ozowBankName = 'ABSA'; branchCode = '632005'; break;
            case 'accessbank': ozowBankName = 'Access Bank'; branchCode = ''; break;
            case 'access': ozowBankName = 'Access Bank'; branchCode = ''; break;
            case 'africanbank': ozowBankName = 'African Bank'; branchCode = '430000'; break;
            case 'african': ozowBankName = 'African Bank'; branchCode = '430000'; break;
            case 'albarakabank': ozowBankName = 'Albaraka Bank'; branchCode = '800000'; break;
            case 'albaraka': ozowBankName = 'Albaraka Bank'; branchCode = '800000'; break;
            case 'bankofathens': ozowBankName = 'Bank Of Athens'; branchCode = '410506'; break;
            case 'athensbank': ozowBankName = 'Bank Of Athens'; branchCode = '410506'; break;
            case 'bankathens': ozowBankName = 'Bank Of Athens'; branchCode = '410506'; break;
            case 'athens': ozowBankName = 'Bank Of Athens'; branchCode = '410506'; break;
            case 'bankwindhoek': ozowBankName = 'Bank Windhoek'; branchCode = ''; break;
            case 'windhoekbank': ozowBankName = 'Bank Windhoek'; branchCode = ''; break;
            case 'bankofwindhoek': ozowBankName = 'Bank Windhoek'; branchCode = ''; break;
            case 'bidvestbank': ozowBankName = 'Bidvest Bank'; branchCode = '462005'; break;
            case 'bidvest': ozowBankName = 'Bidvest Bank'; branchCode = '462005'; break;
            case 'capitecbank': ozowBankName = 'Capitec Bank'; branchCode = '470010'; break;
            case 'capitec-capitecbank': ozowBankName = 'Capitec Bank'; branchCode = '470010'; break;
            case 'capitec': ozowBankName = 'Capitec Bank'; branchCode = '470010'; break;
            case 'citibank': ozowBankName = 'CitiBank'; branchCode = '350005'; break;
            case 'citi': ozowBankName = 'CitiBank'; branchCode = '350005'; break;
            case 'diamondbank': ozowBankName = 'Diamond Bank'; branchCode = ''; break;
            case 'diamond': ozowBankName = 'Diamond Bank'; branchCode = ''; break;
            case 'discoverybank': ozowBankName = 'Discovery Bank'; branchCode = '679000'; break;
            case 'discovery': ozowBankName = 'Discovery Bank'; branchCode = '679000'; break;
            case 'firstbank': ozowBankName = 'FirstBank'; branchCode = ''; break;
            case 'fnbnamibia': ozowBankName = 'FNB Namibia'; branchCode = ''; break;
            case 'firstnationalbanknamibia': ozowBankName = 'FNB Namibia'; branchCode = ''; break;
            case 'fnb': ozowBankName = 'FNB'; branchCode = '250655'; break;
            case 'fnb-firstnationalbank': ozowBankName = 'FNB'; branchCode = '250655'; break;
            case 'firstnationalbank': ozowBankName = 'FNB'; branchCode = '250655'; break;
            case 'gtbank': ozowBankName = 'GTBank'; branchCode = ''; break;
            case 'gt': ozowBankName = 'GTBank'; branchCode = ''; break;
            case 'hsbcbank': ozowBankName = 'HSBC Bank'; branchCode = '587000'; break;
            case 'hsbc': ozowBankName = 'HSBC Bank'; branchCode = '587000'; break;
            case 'investec': ozowBankName = 'Investec'; branchCode = '580105'; break;
            case 'investec-investecbank': ozowBankName = 'Investec'; branchCode = '580105'; break;
            case 'mercantilebank': ozowBankName = 'Mercantile Bank'; branchCode = '450105'; break;
            case 'mercantile': ozowBankName = 'Mercantile Bank'; branchCode = '450105'; break;
            case 'nedbank': ozowBankName = 'Nedbank'; branchCode = '198765'; break;
            case 'nedbank-nedbank': ozowBankName = 'Nedbank'; branchCode = '198765'; break;
            case 'nedbankbank': ozowBankName = 'Nedbank'; branchCode = '198765'; break;
            case 'olympusmobile': ozowBankName = 'Olympus Mobile'; branchCode = '585001'; break;
            case 'olympus': ozowBankName = 'Olympus Mobile'; branchCode = '585001'; break;
            case 'rmb': ozowBankName = 'RMB'; branchCode = '250655'; break;
            case 'rmbbank': ozowBankName = 'RMB'; branchCode = '250655'; break;
            case 'sasfinbank': ozowBankName = 'Sasfin Bank'; branchCode = '683000'; break;
            case 'sasfin': ozowBankName = 'Sasfin Bank'; branchCode = '683000'; break;
            case 'standardbank': ozowBankName = 'Standard Bank'; branchCode = '051001'; break;
            case 'stdbank-standardbanksa': ozowBankName = 'Standard Bank'; branchCode = '051001'; break;
            case 'standard': ozowBankName = 'Standard Bank'; branchCode = '051001'; break;
            case 'standardbanknamibia': ozowBankName = 'Standard Bank Namibia'; branchCode = ''; break;
            case 'standardnamibia': ozowBankName = 'Standard Bank Namibia'; branchCode = ''; break;
            case 'standardcharteredbank': ozowBankName = 'Standard Chartered Bank'; branchCode = '730020'; break;
            case 'standardchartered': ozowBankName = 'Standard Chartered Bank'; branchCode = '730020'; break;
            case 'tymebank': ozowBankName = 'TymeBank'; branchCode = '678910'; break;
            case 'tyme': ozowBankName = 'TymeBank'; branchCode = '678910'; break;
            case 'thymebank': ozowBankName = 'TymeBank'; branchCode = '678910'; break;
            case 'thyme': ozowBankName = 'TymeBank'; branchCode = '678910'; break;
            case 'unitedbankforafrica': ozowBankName = 'United Bank for Africa'; branchCode = ''; break;
            case 'unitedbankafrica': ozowBankName = 'United Bank for Africa'; branchCode = ''; break;
            case 'unitedbank': ozowBankName = 'United Bank for Africa'; branchCode = ''; break;
            case 'ubanklimited': ozowBankName = 'Ubank Limited'; branchCode = '431010'; break;
            case 'ubank': ozowBankName = 'Ubank Limited'; branchCode = '431010'; break;
            case 'zenithbank': ozowBankName = 'Zenith Bank'; branchCode = ''; break;
            case 'zenith': ozowBankName = 'Zenith Bank'; branchCode = ''; break;
            default: return false
        }

        return {
            bankname: ozowBankName,
            branchcode: branchCode
        }
    }

    function updateExcelFileChanged(e) {
        let file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = async (event) => {
            try {
                setAllowBulkUpdates(false);
                const arrayBuffer = await file.arrayBuffer();
                const data = new Uint8Array(arrayBuffer);
                const workbook = xlsx.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0].trim();
                const sheet = workbook.Sheets[sheetName];
                const jsonData = xlsx.utils.sheet_to_json(sheet);

                let requiredFields = ["id_passport_number", "first_name", "net_salary_amount", "email"];
                let keys = Object.keys(jsonData[0]);
                console.log("Excel file headers", keys)

                const allItemsIncluded = requiredFields.every(item => keys.includes(item));

                if (!allItemsIncluded) {
                    alert("Uploaded excel file does not have one of the following fields. id_passport_number, first_name, net_salary_amount, email");
                } else {
                    // Format data
                    const formatedEmployees = jsonData.map((emp) => {

                        if (!emp.id_passport_number) {
                            return null
                        }

                        let employeeinfo = {}
                        let bankaccount = {}
                        let employeecontact = {}
                        let employeesalary = {}
                        let employeeDetail = {}
                        let _employee = {}

                        //info
                        if (emp.first_name) {
                            employeeinfo.FirstName = emp.first_name?.toString().trim()
                            employeeinfo.IDNumber = emp.id_passport_number?.toString().trim()
                        }

                        if (emp.last_name) {
                            employeeinfo.LastName = emp.last_name?.toString().trim()
                        }

                        //contact
                        if (emp.cell_no) {
                            employeecontact.IDNumber = emp.id_passport_number?.toString().trim()
                            employeecontact.Mobile = emp.cell_no?.toString().trim()
                        }

                        if (emp.email) {
                            employeecontact.WorkEmail = emp.email?.toString().trim()
                        }

                        //employee
                        if (emp.id_passport_number) {
                            employeeDetail.IDNumber = emp.id_passport_number?.toString().trim()
                            employeeDetail.businessentity = SelectedCompany._id
                        }

                        //bankaccount
                        if (emp.bank_account_bank_name) {
                            bankaccount.IDNumber = emp.id_passport_number?.toString().trim()
                            let format = formatBankName(emp.bank_account_bank_name)
                            bankaccount.bankname = format.bankname
                        }

                        if (emp.bank_account_account_number) {
                            bankaccount.accountnumber = emp.bank_account_account_number
                        }

                        if (emp.bank_account_branch_code) {
                            let format = formatBankName(emp.bank_account_bank_name)
                            bankaccount.branchcode = format.branchcode
                        }

                        if (emp.bank_account_account_type) {
                            bankaccount.type = emp.bank_account_account_type?.toString().trim()
                        }

                        //salary
                        if (emp.net_salary_amount) {
                            employeesalary.IDNumber = emp.id_passport_number?.toString().trim()
                            employeesalary.Amount = emp.net_salary_amount
                            employeesalary.FeeAmount = 3
                            employeesalary.SubscriptionFee = 10
                            employeesalary.SalaryFreq = "M"
                            employeesalary.ElectedAdvancePerc = emp.accessible_percentage || 25
                        }

                        _employee.employeecontact = employeecontact
                        _employee.employeeinfo = employeeinfo
                        _employee.employeesalary = employeesalary
                        _employee.bankaccount = bankaccount
                        _employee.employee = employeeDetail

                        return _employee;
                    });

                    setAllowBulkUpdates(true);
                    setExcelJsonData(formatedEmployees);
                }
            } catch (error) {
                // Handle error
                console.error("An error occurred while processing the excel file:", error);
                alert("An error occurred while processing the excel file. Please try again.");
            }
        };

        // Start reading the file as a Blob
        reader.readAsBinaryString(file);
    }

    async function bulkUpdateEmployees() {
        let employees = excelJsonData

        employees = employees.filter(emp => emp !== null);

        console.log(employees)

        if (employees.length === 0) {
            console.log("Empty Json")
            return
        }
        let args = {
            admin: commonStore.user.IDNumber || commonStore.user.username,
            employees: employees,
            clientName: SelectedCompany.name
        }
        alert("An email will be sent once employees list is populated")
        commonStore.bulkUpdateEmployees(args).then((ret) => {
            console.log(ret)
        })

    }

    function companyAPIChanged(e) {
        if (e.currentTarget.id === 'api') {
            setCompanyAPI(e.target.value);
        }
        if (e.currentTarget.id === 'apiUser') {
            setCompanyAPIUsername(e.target.value);
        }
        if (e.currentTarget.id === 'apiPass') {
            setCompanyAPIPassword(e.target.value);
        }
        if (e.currentTarget.id === 'apiPay') {
            setCompanyAPIPayrollDef(e.target.value);
        }
    }

    function updateAPIDetails() {
        if (CompanyAPI === '' || CompanyAPIUsername === '' || CompanyAPIPassword === '' || CompanyAPIPayrollDef === '') {
            window.alert('Please fill in all API information');
            return
        }

        if (SelectedCompany.status === 'api_active') {
            if (!window.confirm('This API connection is active. Changing the details may break the connection. Are you sure you want to proceed?')) {
                return
            }
        }

        let data = {
            api: CompanyAPI,
            apiUsername: CompanyAPIUsername,
            apiPassword: CompanyAPIPassword,
            payrollDefinitionCode: CompanyAPIPayrollDef,
            status: 'api_inactive'
        }
        let id = SelectedCompany._id;
        commonStore.setLoading(true);
        commonStore.updateClientById(id, data).then((ret) => {
            window.alert('Details saved successfully, please refresh the page');
            commonStore.setLoading(false);
        })

    }

    async function activateAPI() {
        if (SelectedCompany.api_name === 'LabourNet') {
            let id = SelectedCompany._id;
            commonStore.setLoading(true);
            commonStore.loadLabournetInfo(id).then((ret) => {
                let tmpClients = Clients;
                tmpClients[Number(SelectedCompanyIndex)] = ret.businessentity;
                setClients(tmpClients);
                setSelectedCompany(ret.businessentity);
                loadClientData(ret.businessentity);
            });
        }
        if (SelectedCompany.api_name === 'PaySpace') {
            let id = SelectedCompany._id;
            commonStore.setLoading(true);
            commonStore.loadPayspaceInfo(id).then((ret) => {
                let tmpClients = Clients;
                tmpClients[Number(SelectedCompanyIndex)] = ret.businessentity;
                setClients(tmpClients);
                setSelectedCompany(ret.businessentity);
                loadClientData(ret.businessentity);
            });
        }
        if (SelectedCompany.api_name === 'Sage') {
            alert("Please ask admin to populate Sage employees into database")
            return
            let id = SelectedCompany._id;
            commonStore.setLoading(true);
            await commonStore.loadSageInfo(id).then((ret) => {
                let tmpClients = Clients;
                if (ret === '' || ret === undefined || ret === null) {
                    console.log('Failed');
                    commonStore.setLoading(false)
                }
                if (ret) {
                    tmpClients[Number(SelectedCompanyIndex)] = ret.businessentity;
                    setClients(tmpClients);
                    console.log('Success');
                    setSelectedCompany(ret.businessentity);
                    loadClientData(ret.businessentity);
                    commonStore.setLoading(false)
                }
            });
        }
    }

    function fileUploaded(files) {

        var reader = new FileReader();
        var content;
        reader.onload = function (e) {
            // Use reader.result
            content = reader.result;
            commonStore.setLoading(true);
            commonStore.parseCSV(content, 25).then((ret) => {
                setCSVEmployees(ret);
                // console.log(ret);
                // commonStore.setLoading(false);
                commonStore.uploadCSV(ret, SelectedCompany).then((ret) => {
                    commonStore.setLoading(false);
                })
            });
        }
        reader.readAsText(files[0]);
    }

    function allowAdvancesChanged() {
        if (!AllowAdvances) {
            if (window.confirm('You are about to enable advances for this company. This will allow users to request advances going forward, unless they are in the period between the invoice date and the first.')) {
                commonStore.setLoading(true);
                commonStore.updateClientById(SelectedCompany._id, { allowAdvances: true }).then((ret) => {
                    setAllowAdvances(true);
                    commonStore.setLoading(false);
                })

            }
        }
        if (AllowAdvances === true) {
            if (window.confirm('You are about to disable advances for this company. Users will not be able to request advances until you switch this setting back on.')) {
                commonStore.setLoading(true);
                commonStore.updateClientById(SelectedCompany._id, { allowAdvances: false }).then((ret) => {
                    setAllowAdvances(false);
                    commonStore.setLoading(false);
                })
            }
        }
    }

    function invoiceDateChanged(e) {
        setInvoiceDate(Number(e.target.value));
    }

    function updateInvoiceDate() {
        commonStore.setLoading(true);
        commonStore.updateClientById(SelectedCompany._id, { invoiceDate: Number(InvoiceDate) }).then((ret) => {
            window.alert('Invoice date changed successfully');
            commonStore.setLoading(false);
        })
    }

    function optionSelected(e) {
        setActivePage(e.target.value);
    }

    function exportEmployees() {
        let csvString = `IDNumber, EmployeeNumber, api_id, FirstName, LastName, Contact, Email, SalaryAmount, AdvanceFee, AdvancePercentage, PayDate, PrimaryAccountNumber, PrimaryBank \n`;
        for (let i = 0; i < EmployeeList.length; i++) {
            let curEmp = EmployeeList[i];
            csvString = csvString + `${String(curEmp.IDNumber || '') || ''}, ${curEmp.EmployeeNumber || ''}, ${curEmp.api_id || ''}, ${curEmp.employeeinfo?.FirstName || ''}, ${curEmp.employeeinfo?.LastName || ''}, ${curEmp.employeecontact?.Mobile || ''}, ${curEmp.employeecontact?.PrivateEmail || ''}, ${curEmp.employeesalary?.Amount || ''}, ${curEmp.employeesalary?.FeeAmount || ''}, ${curEmp.employeesalary?.ElectedAdvancePerc || ''}, ${curEmp.employeesalary?.PayDate || ''}, ${curEmp.bankaccount?.accountnumber || ''}, ${curEmp.bankaccount?.bankname || ''} \n`;
        }
        csvString = escapeLeadingZeros(csvString);
        let fileName = `${SelectedCompany.name || 'emp'}.csv`;
        const blob = new Blob([csvString], { type: 'text/csv' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // For IE and Edge
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            // For other browsers
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    function escapeLeadingZeros(csvString) {
        const leadingZeroRegex = /^0\d+/gm;
        const lines = csvString.split(/\r\n|\n/);
        for (let i = 0; i < lines.length; i++) {
            if (leadingZeroRegex.test(lines[i])) {
                lines[i] = `'${lines[i]}`;
            }
        }
        return lines.join('\n');
    }

    function deleteEmployees() {
        var IDNumbers = [];
        for (let i = 0; i < EmployeeList.length; i++) {
            let em = EmployeeList[i];
            IDNumbers.push(em.IDNumber);
        }

        let verify = window.confirm(`Are you sure you want to delete ${IDNumbers.length} Users?`)
        if (!verify) {
            return
        }

        commonStore.setLoading(true);
        commonStore.bulkDeleteEmployees(IDNumbers).then((ret) => {
            console.log(ret);
            window.alert('Complete');
            commonStore.setLoading(false);
        })
    }


    if (isMobile()) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <div style={{ height: '7%', marginLeft: '5px', marginRight: '5px' }}>
                    <label className="custom-field" style={{ padding: '0px', marginTop: '5px' }}>
                        <select className='inp' value={SelectedCompanyIndex} onChange={clientSelected}>
                            <option value={'-1'}>Select A Client</option>
                            {Clients.map((client, index) => {
                                return (
                                    <option key={index} value={index}>{client.name}</option>
                                )
                            })}
                        </select>
                    </label>
                </div>

                {(activePage === 'Info') ?
                    <div style={{ width: '100%', height: '82%', display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'scroll' }}>
                        {(SelectedCompanyIndex === '-1') ? null :
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingLeft: '10px', paddingRight: '10px' }}>
                                    <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Address</p>
                                    <CustomInput type='text' id='line1' value={ClientAddress[0]} placeholder='Line 1' onChange={addressChanged} />
                                    <CustomInput type='text' id='line2' value={ClientAddress[1]} placeholder='Line 2' onChange={addressChanged} />
                                    <CustomInput type='text' id='line3' value={ClientAddress[2]} placeholder='Line 3' onChange={addressChanged} />
                                    <CustomInput type='text' id='line4' value={ClientAddress[3]} placeholder='Line 4' onChange={addressChanged} />
                                    <Button onClick={updateAddress} style={{ width: '80%' }}>Update Address</Button>
                                </div>

                                <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingLeft: '10px', paddingRight: '10px' }}>
                                    <p style={{ fontSize: '15px', fontWeight: 'bold' }}>VAT</p>
                                    <CustomInput type='text' value={ClientVAT} placeholder='VAT Number' onChange={VATChanged} />
                                    <Button onClick={updateVAT} style={{ width: '80%' }}>Update VAT</Button>
                                </div>

                                <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingLeft: '10px', paddingRight: '10px' }}>
                                    <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Invoice Date</p>
                                    <p><b>Note: </b>Users will not be able to request advances between the invoice date and the 1st of the next month.</p>
                                    <CustomInput type='Number' value={String(InvoiceDate)} placeholder='InvoiceDate' onChange={invoiceDateChanged} />
                                    <Button onClick={updateInvoiceDate}>Update Invoice Date</Button>
                                </div>
                            </div>
                        }
                    </div>
                    : null}

                {(activePage === 'Metrics') ?
                    <div style={{ width: '100%', height: '82%', display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'scroll' }}>
                        {(SelectedCompanyIndex === '-1') ? null :
                            <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingLeft: '10px', paddingRight: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                    <p className='adminClientHeader'>Advances</p>
                                    <ToggleSwitch isOn={AllowAdvances} handleToggle={allowAdvancesChanged} />
                                </div>
                                <Card className='AdminClientCard' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                                    <div className='clientAdvDiv'>
                                        <p>Total Advances</p>
                                        <p><b>{`R${TotalAdvances}`}</b></p>
                                    </div>
                                    <div className='clientAdvDiv'>
                                        <p>Total Fees</p>
                                        <p><b>{`R${TotalFees}`}</b></p>
                                    </div>
                                </Card>
                                <Card className='AdminClientCard' style={{ width: '100%' }}>
                                    <ClientAdvanceGraph advances={Advances} includeAdvance={ShowAdvances} includeFees={ShowFees} />
                                    <br />
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                        <Button className={`${(ShowAdvances) ? '' : 'transparentButton'}`} onClick={toggleAdvances}>Advances</Button>
                                        <Button className={`${(ShowFees) ? '' : 'transparentButton'}`} onClick={toggleFees}>Fees</Button>
                                    </div>
                                </Card>
                                <Card className='AdminClientCard' style={{ width: '100%' }}>
                                    <ClientAdvancesPerDay advances={Advances} />

                                </Card>

                                <p className='adminClientHeader'>Employees</p>
                                <Card className='AdminClientCard' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                                    <div className='clientAdvDiv'>
                                        <p>Total Employees</p>
                                        <p><b>{Employees}</b></p>
                                    </div>
                                    <div className='clientAdvDiv'>
                                        <p>Employees Signed Up</p>
                                        <p><b>{EmployeesSignedUp}</b></p>
                                    </div>
                                </Card>

                            </div>
                        }
                    </div>
                    : null}

                {(activePage === 'Invoice') ?
                    <div style={{ width: '100%', height: '82%', display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'scroll' }}>
                        {(SelectedCompanyIndex === '-1') ? null :
                            <div style={{ margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingLeft: '10px', paddingRight: '10px' }}>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Pending Invoices</p>
                                {(PendingInvoices.length === 0) ? <p>There are no pending invoices for this company.</p> : null}
                                {PendingInvoices.map((invoice, index) => {
                                    return (
                                        <Card key={index} id={invoice._id} className='invoiceCard' style={{ width: '100%' }}>
                                            <div>
                                                <p><b>Invoice Number: </b>{invoice.InvoiceNumber}</p>
                                                <p><b>Date: </b>{(new Date(invoice.InvoiceDate)).toLocaleDateString()}</p>
                                                <p><b>Company Name: </b>{invoice.Businessentity.name}</p>
                                                <p><b>Date Range: </b>{`${(new Date(invoice.StartDate)).toLocaleDateString()} - ${(new Date(invoice.EndDate)).toLocaleDateString()}`}</p>
                                                <div style={{ display: 'flex', fontSize: '20px' }}>
                                                    {/* <FaFileDownload id={invoice._id} style={{cursor: 'pointer'}} onClick={downloadInvoice}/> */}
                                                    {(invoice.Status === 'Pending') ? <FaBan id={invoice._id} style={{ color: 'red', cursor: 'pointer' }} onClick={cancelInvoice} /> : null}
                                                    {(invoice.Status === 'Pending') ? <FaCheck id={invoice._id} style={{ color: 'green', cursor: 'pointer' }} onClick={approveInvoice} /> : null}
                                                </div>
                                            </div>

                                            <div style={{ textAlign: 'right' }}>
                                                <p className={`${(invoice.Status === 'Paid') ? 'advPaid' : ''} ${(invoice.Status === 'Pending') ? 'advPending' : ''} ${(invoice.Status === 'Cancelled') ? 'advFailed' : ''}`}>{invoice.Status}</p>
                                                <p><b>Advance Amount: </b>{invoice.AdvanceAmount}</p>
                                                <p><b>Fee Amount: </b>{invoice.FeeAmount}</p>
                                                <p><b>Membership Amount: </b>{invoice.MembershipAmount}</p>
                                                <hr style={{ margin: '0px' }} />
                                                <p><b>Total Excl.: </b>{invoice.TotalExcl}</p>
                                                <p><b>VAT: </b>{invoice.VAT}</p>
                                                <hr style={{ margin: '0px' }} />
                                                <p><b>Total Incl.: </b>{invoice.TotalIncl}</p>
                                            </div>

                                        </Card>
                                    )
                                })}
                            </div>
                        }
                    </div>
                    : null}

                {(activePage === 'NewComp') ?
                    <div style={{ width: '100%', height: '82%', display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'scroll' }}>

                        <div style={{ margin: '10px' }}>
                            <p>New Company Details</p>
                            <CustomInput type='text' id='name' value={NewCompanyName} onChange={newCompanyChange} placeholder='Name' />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='vat' value={NewCompanyVAT} onChange={newCompanyChange} placeholder='VAT No' />
                                </div>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='reg' value={NewCompanyReg} onChange={newCompanyChange} placeholder='Reg Code' />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='newCompanyInp'>
                                    <label htmlFor='allowAdv'>Allow Advances:</label>
                                    <select value={NewCompanyAllowAdvances} id='allowAdv' onChange={newCompanyChange}>
                                        <option value={false}>No</option>
                                        <option value={true}>Yes</option>
                                    </select>
                                </div>
                                <div className='newCompanyInp'>
                                    <CustomInput type='number' id='invoiceDate' value={String(NewCompanyInvoiceDate)} onChange={newCompanyChange} placeholder='Invoice Date' />
                                </div>
                            </div>
                            <p>New Company Address</p>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='line1' value={NewCompanyAddress[0]} onChange={newCompanyChange} placeholder='Address 1' />
                                </div>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='line2' value={NewCompanyAddress[1]} onChange={newCompanyChange} placeholder='Address 2' />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='line3' value={NewCompanyAddress[2]} onChange={newCompanyChange} placeholder='Address 3' />
                                </div>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='line4' value={NewCompanyAddress[3]} onChange={newCompanyChange} placeholder='Address 4' />
                                </div>
                            </div>
                            <p>New Company API</p>
                            <select value={NewCompanyAPIName} onChange={apiNameChanged}>
                                <option value='noApi'>None</option>
                                <option value='LabourNet'>LabourNet</option>
                            </select>
                            {(NewCompanyAPIName === 'LabourNet') ? <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className='newCompanyInp'>
                                        <CustomInput type='text' id='api' value={NewCompanyAPI} onChange={newCompanyChange} placeholder='Instance' />
                                    </div>
                                    <div className='newCompanyInp'>
                                        <CustomInput type='text' id='apiPay' value={NewCompanyAPIPayrollDef} onChange={newCompanyChange} placeholder='Payroll Reg' />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className='newCompanyInp'>
                                        <CustomInput type='text' id='apiUser' value={NewCompanyAPIUsername} onChange={newCompanyChange} placeholder='Username' />
                                    </div>
                                    <div className='newCompanyInp'>
                                        <CustomInput type='text' id='apiPass' value={NewCompanyAPIPassword} onChange={newCompanyChange} placeholder='Password' />
                                    </div>
                                </div>
                                <Button onClick={testLabournet}>Test Details</Button>
                            </div> : null}
                            <p>New Company User</p>
                            <CustomInput type='text' id='email' value={NewCompanyUserEmail} onChange={newCompanyChange} placeholder='Email' />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='newCompanyInp'>
                                    <CustomInput type='password' id='pass' value={NewCompanyUserPassword} onChange={newCompanyChange} placeholder='Password' />
                                </div>
                                <div className='newCompanyInp'>
                                    <CustomInput type='password' id='passConf' value={NewCompanyUserConfirmPassword} onChange={newCompanyChange} placeholder='ConfirmPassword' />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '10px' }}>
                                <div>
                                    <Button className='transparentButton' onClick={cancelCompanyCreation} style={{ marginRight: '10px' }}>Cancel</Button>
                                    <Button onClick={createCompany} style={{ marginLeft: '10px' }}>Create</Button>
                                </div>
                            </div>
                        </div>

                    </div>
                    : null}

                <Card className="tabBar" style={{ height: '11%' }}>
                    <Form className="tabBarForm" onChange={optionSelected} style={{ overflow: 'hidden' }}>

                        <div className="tabBarItem">
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="infoTab" value="Info" />
                            <FaInfoCircle className={(activePage === "Info") ? "fa fa-thin fa-wallet tabSelected" : "fa fa-thin fa-wallet"} htmlFor="infoTab" style={{ height: '100%' }} />
                            <label className={(activePage === "Info") ? "tabSelected" : null} htmlFor="infoTab">Info</label>
                        </div>

                        <div className="tabBarItem">
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="metricsTab" value="Metrics" />
                            <FaChartBar className={(activePage === "Metrics") ? "fa fa-thin fa-wallet tabSelected" : "fa fa-thin fa-wallet"} htmlFor="metricsTab" style={{ height: '100%' }} />
                            <label className={(activePage === "Metrics") ? "tabSelected" : null} htmlFor="metricsTab">Metrics</label>
                        </div>

                        <div className="tabBarItem">
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="invoiceTab" value="Invoice" />
                            <FaFileInvoice className={(activePage === "Invoice") ? "fa fa-thin fa-wallet tabSelected" : "fa fa-thin fa-wallet"} htmlFor="invoiceTab" style={{ height: '100%' }} />
                            <label className={(activePage === "Invoice") ? "tabSelected" : null} htmlFor="invoiceTab">Invoices</label>
                        </div>


                        <div className="tabBarItem">
                            <input className="tabBar-tools" name="tabBarItems" type="radio" id="newCompTab" value="NewComp" />
                            <FaPlus className={(activePage === "NewComp") ? "fa fa-thin fa-wallet tabSelected" : "fa fa-thin fa-wallet"} htmlFor="newCompTab" style={{ height: '100%' }} />
                            <label className={(activePage === "NewComp") ? "tabSelected" : null} htmlFor="newCompTab">Company</label>
                        </div>
                    </Form>

                </Card>

            </div>
        );
    } else {
        return (
            <View style={{ backgroundColor: '#ffffff' }}>
                <header style={{ backgroundColor: '#ffffff' }}>
                    <div>
                        <p className="txtlead txtLeft" style={{ marginBottom: '0px' }}>Client</p>
                    </div>
                </header>
                <div id="onDemandPay" style={{ backgroundColor: '#ffffff' }}>
                    <div className="desktopDashHeader">
                        <div style={{ width: '40%' }}>
                            {/* <CustomInput type='text' id='IDNumber' value={IDNumber} placeholder='ID/Passport number' onChange={IDNumberChanged} onEnter={findUser} /> */}
                            {/* <p>{SelectedCompany.name || ''}</p> */}
                            <select value={SelectedCompanyIndex} onChange={clientSelected}>
                                <option value={'-1'}>Select A Client</option>
                                {Clients.map((client, index) => {
                                    return (
                                        <option key={index} value={index}>{client.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <Button className='transparentButton' onClick={newCompanyClicked}>New Company</Button>
                    </div>
                    <hr />
                    {(SelectedCompanyIndex === '-1') ? null :
                        <div>
                            {((new Date()).getDate() >= InvoiceDate && InvoiceDate !== 0) ?
                                <div className='adminEmployeeAuthentication'>
                                    <p>Service paused due to invoice date passed. Service will resume on the 1st of next month.</p>
                                </div>
                                : null}
                            <p className='adminClientHeader'>Info</p>
                            <Card className='AdminClientCard'>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Address</p>
                                <CustomInput type='text' id='line1' value={ClientAddress[0]} placeholder='Line 1' onChange={addressChanged} />
                                <CustomInput type='text' id='line2' value={ClientAddress[1]} placeholder='Line 2' onChange={addressChanged} />
                                <CustomInput type='text' id='line3' value={ClientAddress[2]} placeholder='Line 3' onChange={addressChanged} />
                                <CustomInput type='text' id='line4' value={ClientAddress[3]} placeholder='Line 4' onChange={addressChanged} />
                                <Button onClick={updateAddress}>Update Address</Button>
                            </Card>
                            <Card className='AdminClientCard'>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>VAT</p>
                                <CustomInput type='text' value={ClientVAT} placeholder='VAT Number' onChange={VATChanged} />
                                <Button onClick={updateVAT}>Update VAT</Button>
                            </Card>
                            <Card className='AdminClientCard'>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Invoice Date</p>
                                <p><b>Note: </b>Users will not be able to request advances between the invoice date and the 1st of the next month.</p>
                                <CustomInput type='Number' value={String(InvoiceDate)} placeholder='InvoiceDate' onChange={invoiceDateChanged} />
                                <Button onClick={updateInvoiceDate}>Update Invoice Date</Button>
                            </Card>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p className='adminClientHeader'>Advances</p>
                                <ToggleSwitch isOn={AllowAdvances} handleToggle={allowAdvancesChanged} />
                            </div>
                            <Card className='AdminClientCard' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                <div className='clientAdvDiv'>
                                    <p>Total Advances</p>
                                    <p><b>{`R${TotalAdvances}`}</b></p>
                                </div>
                                <div className='clientAdvDiv'>
                                    <p>Total Fees</p>
                                    <p><b>{`R${TotalFees}`}</b></p>
                                </div>
                            </Card>
                            <Card className='AdminClientCard'>
                                <ClientAdvanceGraph advances={Advances} includeAdvance={ShowAdvances} includeFees={ShowFees} />
                                <br />
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                    <Button className={`${(ShowAdvances) ? '' : 'transparentButton'}`} onClick={toggleAdvances}>Advances</Button>
                                    <Button className={`${(ShowFees) ? '' : 'transparentButton'}`} onClick={toggleFees}>Fees</Button>
                                </div>
                            </Card>
                            <Card className='AdminClientCard'>
                                <ClientAdvancesPerDay advances={Advances} />
                            </Card>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <p className='adminClientHeader'>Employees</p>
                                <FaFileCsv style={{ cursor: 'pointer' }} onClick={exportEmployees} />
                                <FaTrash onClick={deleteEmployees} />
                            </div>
                            <Card className='AdminClientCard' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                <div className='clientAdvDiv'>
                                    <p>Total Employees</p>
                                    <p><b>{Employees}</b></p>
                                </div>
                                <div className='clientAdvDiv'>
                                    <p>Employees Signed Up</p>
                                    <p><b>{EmployeesSignedUp}</b></p>
                                </div>
                            </Card>
                            <ReactFileReader handleFiles={fileUploaded} fileTypes={'.csv'}>
                                <button style={{ color: 'white' }} className='btn'>Upload CSV</button>
                                {/* <FaUpload style={{cursor: 'pointer'}} className="fa fa-upload" aria-hidden="true"/> */}
                            </ReactFileReader>
                            <p className='adminClientHeader'>Invoices</p>
                            <Card className='AdminClientCard'>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Pending Invoices</p>
                                {(PendingInvoices.length === 0) ? <p>There are no pending invoices for this company.</p> : null}
                                {PendingInvoices.map((invoice, index) => {
                                    return (
                                        <Card key={index} id={invoice._id} className='invoiceCard'>
                                            <div>
                                                <p><b>Invoice Number: </b>{invoice.InvoiceNumber}</p>
                                                <p><b>Date: </b>{(new Date(invoice.InvoiceDate)).toLocaleDateString()}</p>
                                                <p><b>Company Name: </b>{invoice.Businessentity.name}</p>
                                                <p><b>Date Range: </b>{`${(new Date(invoice.StartDate)).toLocaleDateString()} - ${(new Date(invoice.EndDate)).toLocaleDateString()}`}</p>
                                                <div style={{ display: 'flex', fontSize: '20px' }}>
                                                    {/* <FaFileDownload id={invoice._id} style={{cursor: 'pointer'}} onClick={downloadInvoice}/> */}
                                                    {(invoice.Status === 'Pending') ? <FaBan id={invoice._id} style={{ color: 'red', cursor: 'pointer' }} onClick={cancelInvoice} /> : null}
                                                    {(invoice.Status === 'Pending') ? <FaCheck id={invoice._id} style={{ color: 'green', cursor: 'pointer' }} onClick={approveInvoice} /> : null}
                                                </div>
                                            </div>

                                            <div style={{ textAlign: 'right' }}>
                                                <p className={`${(invoice.Status === 'Paid') ? 'advPaid' : ''} ${(invoice.Status === 'Pending') ? 'advPending' : ''} ${(invoice.Status === 'Cancelled') ? 'advFailed' : ''}`}>{invoice.Status}</p>
                                                <p><b>Advance Amount: </b>{invoice.AdvanceAmount}</p>
                                                <p><b>Fee Amount: </b>{invoice.FeeAmount}</p>
                                                <p><b>Membership Amount: </b>{invoice.MembershipAmount}</p>
                                                <hr style={{ margin: '0px' }} />
                                                <p><b>Total Excl.: </b>{invoice.TotalExcl}</p>
                                                <p><b>VAT: </b>{invoice.VAT}</p>
                                                <hr style={{ margin: '0px' }} />
                                                <p><b>Total Incl.: </b>{invoice.TotalIncl}</p>
                                            </div>

                                        </Card>
                                    )
                                })}

                            </Card>
                            <p className='adminClientHeader'>Update Employees</p>
                            <Card className='AdminClientCard'>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Excel upload updated employee details</p>
                                <div>
                                    <p>Upload</p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className='newCompanyInp'>
                                            <CustomInput
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                type='file' onChange={updateExcelFileChanged}

                                            />
                                            {allowBulkUpdates && <Button onClick={bulkUpdateEmployees}>Upload</Button>}
                                        </div>
                                    </div>
                                </div>
                            </Card>

                            <p className='adminClientHeader'>API</p>
                            <Card className='AdminClientCard'>
                                <p style={{ fontSize: '15px', fontWeight: 'bold' }}>API Details</p>
                                {(SelectedCompany.status === 'csv') ? <p>This client has been imported from a CSV file.</p> : null}
                                {(SelectedCompany.status === 'api_missingInfo') ? <div>
                                    <div className='warningDiv'>
                                        <p><b>API details are missing.</b></p>
                                    </div>
                                </div> : null}
                                {(SelectedCompany.api_name && SelectedCompany.api_name === 'Sage') ? <div>
                                    <p><b>Payroll Provider: </b>{SelectedCompany.api_name}</p>
                                    <p><b>API Status: </b><span style={{ color: (SelectedCompany.status === 'api_active') ? 'green' : (SelectedCompany.status === 'api_inactive') ? 'orange' : 'red' }}>{SelectedCompany.status}</span></p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className='newCompanyInp'>
                                            <CustomInput type='text' id='api' value={CompanyAPI} onChange={companyAPIChanged} placeholder='Instance' />
                                        </div>
                                        <div className='newCompanyInp'>
                                            <CustomInput type='text' id='apiPay' value={CompanyAPIPayrollDef} onChange={companyAPIChanged} placeholder='Payroll Reg' />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className='newCompanyInp'>
                                            <CustomInput type='text' id='apiUser' value={CompanyAPIUsername} onChange={companyAPIChanged} placeholder='Username' />
                                        </div>
                                        <div className='newCompanyInp'>
                                            <CustomInput type='text' id='apiPass' value={CompanyAPIPassword} onChange={companyAPIChanged} placeholder='Password' />
                                        </div>
                                    </div>
                                </div> : null}

                                {(SelectedCompany.api_name && SelectedCompany.api_name === 'LabourNet') ? <div>
                                    <p><b>Payroll Provider: </b>{SelectedCompany.api_name}</p>
                                    <p><b>API Status: </b><span style={{ color: (SelectedCompany.status === 'api_active') ? 'green' : (SelectedCompany.status === 'api_inactive') ? 'orange' : 'red' }}>{SelectedCompany.status}</span></p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className='newCompanyInp'>
                                            <CustomInput type='text' id='api' value={CompanyAPI} onChange={companyAPIChanged} placeholder='Instance' />
                                        </div>
                                        <div className='newCompanyInp'>
                                            <CustomInput type='text' id='apiPay' value={CompanyAPIPayrollDef} onChange={companyAPIChanged} placeholder='Payroll Reg' />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className='newCompanyInp'>
                                            <CustomInput type='text' id='apiUser' value={CompanyAPIUsername} onChange={companyAPIChanged} placeholder='Username' />
                                        </div>
                                        <div className='newCompanyInp'>
                                            <CustomInput type='text' id='apiPass' value={CompanyAPIPassword} onChange={companyAPIChanged} placeholder='Password' />
                                        </div>
                                    </div>
                                </div> : null}

                                {(SelectedCompany.api_name && SelectedCompany.api_name === 'PaySpace') ? <div>
                                    <p><b>Payroll Provider: </b>{SelectedCompany.api_name}</p>
                                    <p><b>API Status: </b><span style={{ color: (SelectedCompany.status === 'api_active') ? 'green' : (SelectedCompany.status === 'api_inactive') ? 'orange' : 'red' }}>{SelectedCompany.status}</span></p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className='newCompanyInp'>
                                            <CustomInput type='text' id='api' value={CompanyAPI} onChange={companyAPIChanged} placeholder='Instance' />
                                        </div>
                                        <div className='newCompanyInp'>
                                            <CustomInput type='text' id='apiPay' value={CompanyAPIPayrollDef} onChange={companyAPIChanged} placeholder='Payroll Reg' />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className='newCompanyInp'>
                                            <CustomInput type='text' id='apiUser' value={CompanyAPIUsername} onChange={companyAPIChanged} placeholder='Username' />
                                        </div>
                                        <div className='newCompanyInp'>
                                            <CustomInput type='text' id='apiPass' value={CompanyAPIPassword} onChange={companyAPIChanged} placeholder='Password' />
                                        </div>
                                    </div>
                                </div> : null}
                                {(SelectedCompany.status !== 'csv') ? <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '10px' }}>
                                    <div>
                                        <Button onClick={updateAPIDetails} style={{ marginRight: '10px' }}>Update</Button>
                                        {(SelectedCompany.status === 'api_inactive') ? <Button onClick={activateAPI} style={{ marginRight: '10px' }}>Activate</Button> : null}
                                    </div>
                                </div> : null}
                            </Card>
                        </div>
                    }

                </div>
                {(!ShowNewCompany) ? null :
                    <Card className='newCompanyCard'>
                        <p>New Company Details</p>
                        <CustomInput type='text' id='name' value={NewCompanyName} onChange={newCompanyChange} placeholder='Name' />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='newCompanyInp'>
                                <CustomInput type='text' id='vat' value={NewCompanyVAT} onChange={newCompanyChange} placeholder='VAT No' />
                            </div>
                            <div className='newCompanyInp'>
                                <CustomInput type='text' id='reg' value={NewCompanyReg} onChange={newCompanyChange} placeholder='Reg Code' />
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='newCompanyInp'>
                                <label htmlFor='allowAdv'>Allow Advances:</label>
                                <select value={NewCompanyAllowAdvances} id='allowAdv' onChange={newCompanyChange}>
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </select>
                            </div>
                            <div className='newCompanyInp'>
                                <CustomInput type='number' id='invoiceDate' value={String(NewCompanyInvoiceDate)} onChange={newCompanyChange} placeholder='Invoice Date' />
                            </div>
                        </div>
                        <p>New Company Address</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='newCompanyInp'>
                                <CustomInput type='text' id='line1' value={NewCompanyAddress[0]} onChange={newCompanyChange} placeholder='Address 1' />
                            </div>
                            <div className='newCompanyInp'>
                                <CustomInput type='text' id='line2' value={NewCompanyAddress[1]} onChange={newCompanyChange} placeholder='Address 2' />
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='newCompanyInp'>
                                <CustomInput type='text' id='line3' value={NewCompanyAddress[2]} onChange={newCompanyChange} placeholder='Address 3' />
                            </div>
                            <div className='newCompanyInp'>
                                <CustomInput type='text' id='line4' value={NewCompanyAddress[3]} onChange={newCompanyChange} placeholder='Address 4' />
                            </div>
                        </div>
                        <p>New Company API</p>
                        <select value={NewCompanyAPIName} onChange={apiNameChanged}>
                            <option value='noApi'>None</option>
                            <option value='LabourNet'>LabourNet</option>
                            <option value='PaySpace'>PaySpace</option>
                        </select>
                        {(NewCompanyAPIName === 'LabourNet') ? <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='api' value={NewCompanyAPI} onChange={newCompanyChange} placeholder='Instance' />
                                </div>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='apiPay' value={NewCompanyAPIPayrollDef} onChange={newCompanyChange} placeholder='Payroll Reg' />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='apiUser' value={NewCompanyAPIUsername} onChange={newCompanyChange} placeholder='Username' />
                                </div>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='apiPass' value={NewCompanyAPIPassword} onChange={newCompanyChange} placeholder='Password' />
                                </div>
                            </div>
                            <Button onClick={testLabournet}>Test Details</Button>
                        </div> : null}

                        {(NewCompanyAPIName === 'PaySpace') ? <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='api' value={NewCompanyAPI} onChange={newCompanyChange} placeholder='Company Id' />
                                </div>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='apiPay' value={NewCompanyAPIPayrollDef} onChange={newCompanyChange} placeholder='Scope' />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='apiUser' value={NewCompanyAPIUsername} onChange={newCompanyChange} placeholder='Client Id' />
                                </div>
                                <div className='newCompanyInp'>
                                    <CustomInput type='text' id='apiPass' value={NewCompanyAPIPassword} onChange={newCompanyChange} placeholder='Client Secret' />
                                </div>
                            </div>
                            <Button onClick={testPaySpace}>Test Details</Button>
                        </div> : null}



                        <p>New Company User</p>
                        <CustomInput type='text' id='email' value={NewCompanyUserEmail} onChange={newCompanyChange} placeholder='Email' />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='newCompanyInp'>
                                <CustomInput type='password' id='pass' value={NewCompanyUserPassword} onChange={newCompanyChange} placeholder='Password' />
                            </div>
                            <div className='newCompanyInp'>
                                <CustomInput type='password' id='passConf' value={NewCompanyUserConfirmPassword} onChange={newCompanyChange} placeholder='ConfirmPassword' />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '10px' }}>
                            <div>
                                <Button className='transparentButton' onClick={cancelCompanyCreation} style={{ marginRight: '10px' }}>Cancel</Button>
                                <Button onClick={createCompany} style={{ marginLeft: '10px' }}>Create</Button>
                            </div>
                        </div>
                    </Card>
                }

            </View>
        );
    }

})

export default Client;