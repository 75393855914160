import React, {useState,useEffect} from 'react';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { isMobile } from 'is-mobile';
import { Button, Card } from '../../../components/basic';
import { View } from '../../../shared/components';
import { ClientAdvanceGraph } from '../../../components';
import { FaRedo } from 'react-icons/fa';

import '../../style.scss';
import './style.scss';

const Home = observer(() => {

    const navigate = useNavigate();
    const commonStore = useStore();

    const [Advances, setAdvances] = useState([]);
    const [SuccessfulAdvances, setSuccessfulAdvances] = useState([]);
    const [TotalAdvances, setTotalAdvances] = useState(0);
    const [TotalFees, setTotalFees] = useState(0);
    const [GraphOffset, setGraphOffset] = useState(0);
    const [Invoices, setInvoices] = useState([]);
    const [PendingInvoices, setPendingInvoices] = useState([]);
    const [OzowFloatBalance, setOzowFloatBalance] = useState(0);

    useEffect(() => {
        getOzowBalance();
    }, []);

    function getOzowBalance(){
        commonStore.setLoading(true);
        commonStore.getOzowBalance().then((ret) => {
            setOzowFloatBalance(ret);
            // loadAdvances();
            commonStore.setLoading(false);
        })
    }

    function refreshOzowBalance(){
        commonStore.setLoading(true);
        commonStore.getOzowBalance().then((ret) => {
            setOzowFloatBalance(ret);
            commonStore.setLoading(false);
        })
    }

    function loadAdvances(){
        commonStore.setLoading(true);
        commonStore.getAdvances().then((ret) => {
            let succAdv = ret.filter(adv => adv.transactionhistory && adv.transactionhistory.TransactionStatus && adv.transactionhistory.TransactionStatus !== 'Failed');
            setSuccessfulAdvances(succAdv);
            setAdvances(ret);
            var tAdv = 0;
            var tFee = 0;
            for (let i = 0; i < succAdv.length; i++){
                tAdv += succAdv[i].AdvanceAmount;
                tFee += Number(succAdv[i].AdvanceFee || 0);
            }
            setTotalAdvances(tAdv);
            setTotalFees(tFee);
            loadInvoices();
        })
    }

    function loadInvoices(){
        commonStore.getInvoices().then((ret) => {
            setInvoices(ret);
            let pendInv = ret.filter(inv => inv.Status === 'Pending');
            setPendingInvoices(pendInv);
            commonStore.setLoading(false);
        })
    }

    function graphOffset(e){
        if (e.currentTarget.id === 'prev'){
            setGraphOffset(GraphOffset + 1);
        }else{
            if (GraphOffset === 0){
                return
            }
            setGraphOffset(GraphOffset - 1);
        }
    }
        
        
        if (isMobile()){
            return(
                <div style={{width: '100%', height: '100%', padding: '10px', overflow: 'scroll'}}>
                    {(OzowFloatBalance === 'error') ? 
                    <div className='ozowFloatBalanceSection ozowBad'>
                        <p>Failed to Retrieve Ozow Float Balance</p>
                        <FaRedo style={{cursor: 'pointer'}} onClick={refreshOzowBalance}/>
                    </div>
                    :
                    <div className={`ozowFloatBalanceSection ${(OzowFloatBalance > 50000) ? 'ozowGood' : ''}${(OzowFloatBalance < 50000 && OzowFloatBalance >= 10000) ? 'ozowMiddle' : ''}${(OzowFloatBalance < 10000) ? 'ozowBad' : ''}`}>
                        <p>Ozow Float Balance</p>
                        <p>{`R${OzowFloatBalance}`}</p>
                    </div>}
                </div>
            );
        }else{
            return(
                <View style={{backgroundColor: '#ffffff'}}>
                    <header style={{backgroundColor: '#ffffff'}}>
                        <div>
                            <p className="txtlead txtLeft" style={{marginBottom: '0px'}}>Home</p>
                        </div>
                    </header>
                    <div id="onDemandPay" style={{backgroundColor: '#ffffff'}}>
                        <hr/>
                        {(OzowFloatBalance === 'error') ? 
                        <div className='ozowFloatBalanceSection ozowBad'>
                            <p>Failed to Retrieve Ozow Float Balance</p>
                            <FaRedo style={{cursor: 'pointer'}} onClick={refreshOzowBalance}/>
                        </div>
                        :
                        <div className={`ozowFloatBalanceSection ${(OzowFloatBalance > 50000) ? 'ozowGood' : ''}${(OzowFloatBalance < 50000 && OzowFloatBalance >= 10000) ? 'ozowMiddle' : ''}${(OzowFloatBalance < 10000) ? 'ozowBad' : ''}`}>
                            <p>Ozow Float Balance</p>
                            <p>{`R${OzowFloatBalance}`}</p>
                        </div>}
                        <p style={{fontWeight: 'bold'}}>Advances</p>
                        <Card className='AdminClientCard' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <div className='clientAdvDiv'>
                                <p>Total Advances</p>
                                <p>{`R${TotalAdvances.toFixed(2)}`}</p>
                            </div>
                            <div className='clientAdvDiv'>
                                <p>Total Fees</p>
                                <p>{`R${TotalFees.toFixed(2)}`}</p>
                            </div>
                        </Card>
                        <Card className='AdminClientCard'>
                            <ClientAdvanceGraph advances={SuccessfulAdvances} includeAdvance={true} includeFees={true} offset={GraphOffset}/>
                            <br/>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                                <Button id='prev' onClick={graphOffset}>Previous</Button>
                                <Button id='next' onClick={graphOffset}>Next</Button>
                            </div>
                        </Card>
                    </div>
                    <p style={{fontWeight: 'bold'}}>Invoices</p>
                    {PendingInvoices.map((invoice, index) => {
                            return (
                                <Card key={index} id={invoice._id} className='invoiceCard'>
                                    <div>
                                        <p><b>Invoice Number: </b>{invoice.InvoiceNumber}</p>
                                        <p><b>Date: </b>{(new Date(invoice.InvoiceDate)).toLocaleDateString()}</p>
                                        <p><b>Company Name: </b>{invoice.Businessentity.name}</p>
                                        <p><b>Date Range: </b>{`${(new Date(invoice.StartDate)).toLocaleDateString()} - ${(new Date(invoice.EndDate)).toLocaleDateString()}`}</p>
                                        <div style={{display: 'flex', fontSize: '20px'}}>
                                        <div>

                                        </div>
                                  
                                        </div>
                                    </div>

                                    <div style={{textAlign: 'right'}}>
                                        <p className={`${(invoice.Status === 'Paid') ? 'advPaid' : ''} ${(invoice.Status === 'Pending') ? 'advPending' : ''} ${(invoice.Status === 'Cancelled') ? 'advFailed' : ''}`}>{invoice.Status}</p>
                                        <p><b>Advance Amount: </b>{invoice.AdvanceAmount}</p>
                                        <p><b>Fee Amount: </b>{invoice.FeeAmount}</p>
                                        <p><b>Membership Amount: </b>{invoice.MembershipAmount}</p>
                                        <hr style={{margin: '0px'}}/>
                                        <p><b>Total Excl.: </b>{invoice.TotalExcl}</p>
                                        <p><b>VAT: </b>{invoice.VAT}</p>
                                        <hr style={{margin: '0px'}}/>
                                        <p><b>Total Incl.: </b>{invoice.TotalIncl}</p>
                                    </div>
                                    
                                </Card>
                            )
                        })}


                </View>
            );
        }
})

export default Home;